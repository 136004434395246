@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Prompt&family=Ubuntu:wght@300&display=swap);
* {
  font-style: normal;
  font-feature-settings: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  font-family: "prompt", sans-serif;
}

* {
  font-style: normal;
  font-feature-settings: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  line-height: 20px;
  font-family: "prompt", sans-serif;
}

@media only screen and (max-width: 600px) {
  /* select{
      background-color: white !important;
  } */
  input {
    background-color: white !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
.PageWrapper {
  display: flex;
  flex-direction: column;
  /* min-height: 135vh; */
}

.AppWrapper {
  flex-grow: 1;
}

.FooterWrapper {
  flex-shrink: 0;
  z-index: 1;
}



/* html,
body {
  margin: 0;
  padding: 0;
} */
.dashboard-activateTag-popup {
  font-family: "prompt", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}
.dashboard-input__field__dialogs {
  padding: 0.375em 0.75em;
  border: 1px solid black;
  border-radius: 15px;
}
.dashboard-verifyOtp-button {
  font-family: "prompt", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #ffc107;
  letter-spacing: 1px;
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}

.dashboard-verifyOtp-button:hover {
  background-color: #ebb105;
}
.left-image {
  /* border: 1px solid brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  padding: 1rem;
  margin-top: 0rem;
  width: 50%;
}
.bagActivation-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}
.bag-container {
  /* background-image: url("../../assets/grey.png"); */
  /* background-size: auto;
  background-repeat: no-repeat;
  background-position: center center; */
  
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}


.background-image {
  background: whitesmoke;
  padding: 20px;
  border-radius: 10px;
  max-width: 900px;
  width: 100%;
}

.bagActivation-left-wrapper {
  position: relative;
  background-color: white;
}
.bag-activation-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 5em;
  gap: 5em;
}
.bagActivation-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.bagActivation-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.bagActivation-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.bagActivation-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.bagActivation-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.bagActivation-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bagActivation-sidebar-menu-ul-hide {
  display: none;
}

.bagActivation-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}

.bag-activation-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  padding: 1em;
}

.bagactivation-label-container {
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.bagactivation-label-petdata {
  font-size: 1.5rem;
  margin-top: 1em;
  margin-bottom: 1em;
}

.bag-activation-container > p {
  font-family: "prompt", sans-serif;
  text-align: center;
}

.bagactivation-input-tag {
  margin-top: 0 !important;
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}

.bagactivation-drop-down {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
}

.bagactivation-btn-grp {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  padding: 1rem 5rem 1rem 4rem;
}

.bag-image-upload {
  /* width: 120px;
  height: 120px;
  margin: 0rem 9rem 20rem -9rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  grid-gap: 0.9rem;
  gap: 0.9rem;
  /* display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      marginBottom: "5rem",
                      gap: "0.9em", */
}

#choose-profile-imageActi {
  display: none;
}

.key-activation input {
  padding: 0px !important;
}

.mat-raised-button:disabled {
  background-color: #fcbc1b;
}

.bag-image {
  height: 256px;
  width: 256px;
  border-radius: 20%;
}

.overlay {
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity: 0;
  border-radius: 50%;
}
.button-image {
  background-color: transparent !important;
  color: white !important;
  border: none;
  outline: none;
}
.button-image:focus {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}
.fa-edit {
  margin-top: 48px;
  color: white;
}

.plus-icon {
  margin-top: 0px;
  color: #a1a1a1;
}

.image-upload:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.leftside-navigation {
  list-style: none;
  margin: auto;
  margin-left: -30px;
}

#uploadPreviewActi {
  width: 120px;
  height: 120px;
  margin-top: -148px !important;
  border-radius: 50%;
  border: none;
  outline: none;
}

#uploadPreview {
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  display: none;
  border: none;
  outline: none;
}

.left-side {
  border-radius: 15px 0 0 15px;
  background-color: rgb(255, 234, 177);
}

.row-underline {
  border-bottom: 5px solid #fcbc1b;
}

textarea {
  min-height: 20px !important;
  border: 2px solid #e4e4e4;
  width: 200px !important;
  font-size: 14px;
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid #e4e4e4 !important;
}

.input-pet-details {
  border: none;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1 {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2 {
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 13px;
  padding: 0px;
}

.label-petdata {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt", sans-serif;
}

.label-petdata-1 {
  font-size: 16px;
  color: #494949;
  font-weight: 800;
  font-family: "prompt", sans-serif;
}

p strong {
  font-size: 30px;
}

.color-gray {
  color: #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}

input:focus {
  border: none !important;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

input:focus,
select:focus,
button:focus {
  outline: none !important;
}

/* input[readonly] {
  background-color: white;
} */

.submit-login {
  width: 100%;
  max-height: 35px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.file {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #fcbc1b;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-size: 13px;
}

.gender-male {
  font-weight: none;
  font-size: 13px;
}

.gender-female {
  font-weight: none;
  font-size: 13px;
}

.multiple-images {
  transition: transform 0.3s;
}

.multiple-images:hover {
  transform: scale(2.5);
}

@media only screen and (max-width: 990px) {
  .body-login {
    width: 100%;
    margin-top: 50px !important;
  }
  .marginheight {
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav {
    height: 100% !important;
    width: 100%;
  }
  .petparentlinks {
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .row-underline {
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 850px) {
  .body-login {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .label-petdata {
    text-align: center;
  }
  .label-petdata-1 {
    text-align: center;
  }

  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .marginheight {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 576px) {
  .below-gender-move {
    margin-top: 0px;
  }
  .body-login {
    border-radius: 0px;
  }
  .left-side {
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons {
    margin-left: 0px !important;
  }

  .bag-activation-form {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .bag-image-upload {
    margin: auto;
  }
}

@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
  .bag-container {
  
    height: auto;
  }
  .bag-activation-form {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .bag-image-upload {
    margin: auto;
  }
}

@media only screen and (max-width: 360px) {
  .input-pet-details {
    width: 200px;
  }

  .input-pet-details-2 {
    width: 200px;
  }

  .input-pet-details-dropdown {
    width: 200px;
  }
  .submit-login {
    width: 200px !important;
  }

  .bag-activation-form {
    flex-direction: column;
    align-items: stretch;
    grid-gap: 0;
    gap: 0;
  }
}
@media only screen and (max-width: 375px) {

  .bag-activation-form {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
  .bag-image-upload {
    margin: auto;
  }
}
@media only screen and (max-width: 330px) {
  .gender-female {
    margin-top: -40px !important;
  }
  .below-gender-move {
    margin-top: 40px;
  }
  .leftside-buttons {
    width: 160px !important;
  }
}

@media only screen and (max-width: 300px) {
  .input-pet-details {
    width: 170px;
  }

  .input-pet-details-2 {
    width: 170px;
  }

  .input-pet-details-dropdown {
    width: 170px;
  }
}

/* @media screen and (min-width: 48em) {
  .bag-activation-container {
    padding: 0 7em;
  }
} */

@media screen and (min-width: 62.5em) {
  .bagActivation-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .bagActivation-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (min-width: 75em) {
  .bag-activation-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* .bag-activation-form {
    padding: 0 10%;
  } */
}

/* Media query for small mobile screens */
@media (max-width: 768px) {
  .left-image {
    width: 100%; /* Change width to occupy the full width of the screen */
    /* Adjust other styles as needed for small screens */
  }
}

@media screen and (min-width: 78.125em) {
  .bagActivation-sidebar-menu-link {
    font-family: "prompt", sans-serif;
    font-size: 1rem;
  }

  .bagActivation-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }

  /* .bag-activation-form {
    padding: 0 20%;
  } */

  /* .bag-activation-container {
    margin: 2% 0;
  } */
}

@media only screen and (max-width: 600px) {
  .bag-container{
    margin-top: 0;
  }
  .editPetDetails-main-wrapper{
    top: 5.3rem;
  }
}

.keydetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}
.editPetDetails-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.keydetails-left-wrapper {
  position: relative;
  background-color: white;
}

.keydetails-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}
.keydetails-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}
.keydetails-right-form-btn-container {
  display: flex;
  /* justify-content: space-evenly;
   */
  padding: 0rem 4rem 0rem 3rem;
  grid-gap: 1em;
  gap: 1em;
}

.editPetDetails-user-img {
  text-align: center;
  position: relative;
}

.editPetDetails-image {
  width: 15rem;
  height: 15rem;
  margin-bottom: 2rem;
  /* border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
}

.editPetDetails-edit-btn-wrap {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 10px;
}

.editPetDetails-edit-button-image,
.editPetDetails-delete-button-image {
  display: inline-block;
  padding: 5px 10px;
  color: #000;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid lightgray;
  transition: background-color 0.3s;
}
.editPetDetails-edit-button-image:hover,
.editPetDetails-delete-button-image:hover {
  background-color: #fcbc1b;
  color: white;
}

.editPetDetails-input-user-img {
  display: none;
}

.editPetDetails-preview-img {
  margin-top: 10px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

/* p {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
} */

.keydetails-right-form-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5em;
  width: 100%;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 4px 5px -3px rgba(0, 0, 0, 0.75);
}

.keydetails-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}
.keydetails-sidebar-container {
  width: 60%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}
.keydetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}
.keydetails-sidebar-menu-ul-hide {
  display: none;
}
.keydetails-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.keydetails-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}
.background-container {
  /* background-image: url("../../assets/grey.png"); */
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-image-details {
  background: linear-gradient(to bottom, #fffdfd, #f4ebeceb, #f1f1f1cc);
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
}
.keydetails-right-wrapper {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 1em; */
}

.keydetails-right-wrapper > p {
  text-align: center;
}
.keydetails-right-form {
  display: flex;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
}
.keydetails-right-form-label-input-container {
  display: flex;
  flex-direction: column;
  /* gap: 0.5em; */
}

.keydetails-right-form-select,
.keydetails-right-form-inputs {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em 2em;
}
/* Main Right Container */
.editPetDetails-right-wrapper {
  background-color: white;
  border-radius: 10px;
  width: 90%;
  margin: auto;
  padding: 15px;
}
.editPetDetails-form-container-top {
  /* border: 1px solid brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
  padding: 1rem;
  margin-top: 0rem;
}
.editPetDetails-user-img {
  text-align: center;
  position: relative;
}
.editPetDetails-image {
  width: 15rem;
  height: 15rem;
  margin-bottom: 2rem;
  
}
.editPetDetails-edit-btn-wrap {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: 10px;
}
.editPetDetails-edit-button-image,
.editPetDetails-delete-button-image {
  display: inline-block;
  padding: 5px 10px;
  color: #000;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid lightgray;
  transition: background-color 0.3s;
}
.editPetDetails-edit-button-image:hover,
.editPetDetails-delete-button-image:hover {
  background-color: #fcbc1b;
  color: white;
}
.editPetDetails-input-user-img {
  display: none;
}
.two-field-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0px;
}

.editPetDetails-input-wrapper {
  width: 49%;
}
.editPetDetails-btn-row {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}
.editPetDetails-main-wrapper-shower {
  width: 100%;
  position: absolute;
  top: 11.3rem;
}
.editPetDetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 11.3rem;
  
}
/* Main Container */
.editPetDetails-main-wrapper-shower {
  width: 100%;
  position: absolute;
  top: 11.3rem;
  
  
}
.editPetDetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 11.3rem;
  background-color: hsla(0, 0%, 78%, 0.5058823529411764);
  
}
@media screen and (max-width: 360px) {
  .editPetDetails-main-wrapper {
    /* width: min-content; */
    width: 100%;
  }
  .editPetDetails-main-wrapper-shower {
    /* width: min-content; */
    width: 100%;
  }
 
}
@media screen and (max-width: 480px) {
  .two-field-wrapper {
    flex-direction: column;
  }
  .editPetDetails-input-wrapper {
    width: 100%;
    margin: 8px 0px;
  }
}
@media only screen and (min-width: 660px) {
  .editPetDetails-form-container {
    flex-direction: row;
  }
 
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .editPetDetails-right-wrapper {
    width: 45rem;
    margin: 3rem 17rem;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .editPetDetails-right-wrapper {
    width: 50rem;
    margin: 3rem 22rem;
  }
}
@media screen and (min-width: 1400px) {
  .editPetDetails-right-wrapper {
    width: 50rem;
    margin: 3rem 28rem;
  }
}
@media screen and (max-width: 360px) {
  
  .editPetDetails-right-wrapper {
    padding: 2.5rem;
  }
}
@media screen and (width: 64em) {
  .editPetDetails-right-wrapper {
    padding: 0 11% 0 23%;
  }
}
@media screen and (min-width: 48em) {
  /* .keydetails-right-wrapper {
    margin: 0 5rem;
    align-items: center;
  } */
  .keydetails-right-form-select,
  .keydetails-right-form-inputs {
    max-width: 15em;
  }
  .keydetails-right-form-btn-container {
    max-width: 25em;
  }
}
@media screen and (min-width: 62.5em) {
  .keydetails-sidebar-toggle-btn-wrapper {
    display: none;
  }
  .keydetails-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
    font-family: "prompt", sans-serif;
  }
}
/* @media screen and (width: 64em) {
  .keydetails-right-wrapper {
    margin-top: 1.5em;
    margin-left: 15em;
  }
} */
/* @media screen and (min-width: 75em) {
  .keydetails-right-wrapper {
    margin-left: 17em;
    padding: 2% 11%;
  }
} */
@media screen and (min-width: 78.125em) {
  .keydetails-sidebar-menu-link {
    font-size: 1rem;
  }
  .keydetails-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 510px) {
  .marginheight {
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 990px) {
  .marginheight {
    margin-top: 0px !important;
  }
}

/* .body-login{
    width: 85%;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    margin-top: 15px;
  }
  
  .image-upload{
    width: 120px;
    height: 120px;
    margin-top:2rem ;
    margin-bottom: 3rem;
  }
  .add_img_btn{
    border: none;
    background-color:#e7e4e4;
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 3rem;
  }
  .add_img_btn:hover{
    cursor: pointer;
    background-color: rgb(251 249 249);
  }

  #choose-profile-imageActi{
      display: none;
  }
  
  .key-activation input{
    padding: 0px !important;
  }
  
  select{
    padding: 0px !important;
  }
  
  .mat-raised-button:disabled{
    background-color:#FCBC1B;
  }
  
  .image{
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
  
  .overlay{
    height: 120px;
    width: 120px;
    background-color: black;
    margin-top: -120px;
    position: absolute;
    opacity:0;
    border-radius: 50%;
  }
  .button-image{
    background-color: transparent;
    border: none;
    outline: none;
  }
  .fa-edit{
    margin-top:48px;
    color: white;
  }
  
  .plus-icon{
    margin-top: 0px;
    color: #a1a1a1;
  }
  
  .image-upload:hover .overlay{
    background-color: rgba(0,0,0,0.5);
    opacity:1;
  }

  .leftside-navigation{
    list-style: none; 
    margin: auto; 
    margin-left: -30px;
  }
  
  #uploadPreviewActi{
    width: 120px;
    height: 120px;
    margin-top: -148px !important;
    border-radius: 50%;
    border:none;
    outline:none;
  }
  
  #uploadPreview{
    width: 120px;
    height: 120px;
    margin-top: -140px !important;
    border-radius: 50%;
    display: none;
    border:none;
    outline:none;
  }
  
  
  .left-side{
    border-radius: 15px 0 0 15px;
    background-color: rgb(255, 234, 177);
  }
  
  .row-underline{
    border-bottom: 5px solid #FCBC1B;
  }

  textarea{
      min-height: 20px !important;
      border: 2px solid #e4e4e4;
      width: 200px !important;
      font-size: 14px;
      outline: none !important;
      box-shadow: none !important;
  }

  textarea:focus{
    outline: none !important;
    box-shadow: none !important;
    border: 2px solid #e4e4e4 !important;
  }
  
  .input-pet-details{
    border: none;
    outline: none !important;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 200px;
    height: 20px;
    font-size: 14px;
  }
  
  .input-pet-details-1{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 440px;
    height: 20px;
    font-size: 14px;
  }
  
  .input-pet-details-2{
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
  }
  
  .input-pet-details-dropdown{
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 13px;
  padding: 0px;
  }
  
  .label-petdata{
    font-size: 16px;
    color: #494949;
    font-weight: 800;
    font-family: "Lato", sans-serif;
  } */

/* .label-petdata-1{
  font-size: 12px;
  color: #777777;
  font-size: 16px;
    color: #494949;
    font-weight: 800;
    font-family: "Lato", sans-serif;
  }
  
  p strong{
  font-size: 18px;
  }
  
  .color-gray{
    color:  #b4b4b4;
  }
  
  ::ng-deep .mat-radio-label-content {
    font-weight: 550 !important;
  }
  
  input:focus{
    border: none !important;
    outline: none !important;
    border-bottom: 2px solid #e4e4e4 !important;
    box-shadow: none !important;
    outline-width: 0 !important;
  }
  
  input:focus,
  select:focus,
  button:focus {
    outline: none !important;
  }
  
  input[readonly] {
    background-color: white;
  }
  
  .submit-login{
    width: 100%;
    max-height: 35px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: 500;
  }
  
  .file{
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #FCBC1B;
    border-radius:5px;
    width:100px;
    height: 30px;
    font-size: 13px;
  }
  
  .gender-male{
      font-weight: none;
      font-size: 13px;
  }
  
  .gender-female{
  font-weight: none;
  font-size: 13px;
  }
  
  .multiple-images{
  transition: transform .3s;
  }
  
  .multiple-images:hover{
  transform: scale(2.5);
  }

  @media only screen and (max-width:990px){
    .body-login{
      width: 100%;
      margin-top: 50px !important;
    }
    .marginheight{
      margin-top: 0px !important;
    }
    .dashboard__blacksidenav{
      height: 100% !important;
      width: 100%;
    }
    .petparentlinks{
      margin-top: 20px !important;
      margin-left: -10px !important;
    }
  }
  
  
  @media only screen and (max-width:850px){
  .body-login{
    width: 100%;
  }
  }
  
  @media only screen and (max-width:770px){
  .label-petdata{
    text-align: center;
  }
  .label-petdata-1{
    text-align: center;
  }
  
  .gender-female{
    margin-top: -40px !important;
  }
  .below-gender-move{
    margin-top: 40px;
  }
  }
  
  @media only screen and (max-width:576px){
  .below-gender-move{
    margin-top: 0px;
  }
  .body-login{
    border-radius: 0px;
  }
  .left-side{
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons{
    margin-left: 0px !important;
  }
  .petparentlinks{
    margin-left: -10px !important;
    margin-top: 20px !important;
  }
  }

  @media only screen and (max-width:510px){
    .marginheight{
      margin-top: 30px !important;
    }
  }
  
  @media only screen and (max-width:360px){
    .input-pet-details{
      width: 200px;
    }
    
    .input-pet-details-2{
    width: 200px;
    }
    
    .input-pet-details-dropdown{
    width: 200px;
    }
    .submit-login{
      width: 200px !important;
    }
    }
    
    @media only screen and (max-width:330px){
      .gender-female{
        margin-top: -40px !important;
      }
      .below-gender-move{
        margin-top: 40px;
      }
      .leftside-buttons{
        width: 160px !important;
      }
      .petparentlinks{
        margin-left: -20px !important;
      }
      }
    
    @media only screen and (max-width:300px){
    .input-pet-details{
      width: 170px;
    }
    
    .input-pet-details-2{
    width: 170px;
    }
    
    .input-pet-details-dropdown{
    width: 170px;
    }
    }
   */

   .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  @media only screen and (max-width: 600px) {
    .bread_crumbs{
      margin-bottom: 2rem;
    }
    .bagActivation-left-wrapper{
      top: -4rem;
    }
    .bag-container{
      margin-top: 0;
    }
    .editPetDetails-main-wrapper{
      top: 8.3rem;
    }
    .editPetDetails-main-wrapper-shower{
      top: 5.3rem;
    }
  }
  @media only screen and (max-width: 767px) {
       /* Your mobile-specific styles here */
   
    .editPetDetails-image {
      width: 10rem;
      height: 10rem;
      margin-bottom: 2rem;
    
    }
}
  
  
/* .header-section {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
/* z-index: 1000; */
/* }  */
.header-change-password-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
  margin: 1em;
}

.header-change-password-popup > label {
  margin: 0px !important;
  font-size: 25px;
  font-weight: bold;
}

.header-change-password-popup-input-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.header-change-password-popup-checkbox-input-container {
  display: flex;
}

.header-change-password-popup-input {
  position: relative;
}

.header-change-password-popup-checkbox {
  display: none;
}

.header-change-password-popup-input {
  margin: 0px !important;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em 2em;
  background-color: white !important;
}

.header-change-password-popup-checkbox-container > label {
  position: absolute;
  padding: 1em;
  right: 1.125em;
}

.header-change-password-popup-btns {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.header-change-password-popup-btns > button {
  border: none;
  border-radius: 5px;
  padding: 1em 1.5em;
}

.header-section {
  position: fixed;
  height: 149px;
  background-color: #ffffff;
  left: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid rgb(167 167 167);
}
.header-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  height: 180px;
  z-index: 1000;
  transition: top 0.3s;
}

.announcement-bar-1-hidden {
  display: none;
}
/* .announcement-bar-1 {
  background: rgb(var(--color-announcement-bar-background-1));
  color: #fff !important;
  text-align: center !important;
  border-bottom: 45px solid #000 !important;
  margin-top: -3.8rem !important;
  width: 105% !important;
  box-sizing: border-box !important;
} */
.announcement-bar-1 {
  background: #252525f5;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.announcement-bar__wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.announcement-bar__message {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  grid-gap: 0.8rem !important;
  gap: 0.8rem !important;
  text-align: center !important;
  padding: 0.6rem 0.6rem !important;
  min-height: 1rem !important;
}
.announcement-bar__wrapper::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
}
.announcement-bar__border {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
}
.announcement-bar__text {
  font-weight: 400 !important;
  line-height: 145% !important;
}
.announcement-bar__text p {
  margin: 0 !important;
}
.announcement-bar__text p strong {
  /* margin: 0 !important; */
  font-size: 0.8rem !important;
}
a {
  position: relative;
  text-decoration: none;
  color: #000;
}
/* a :hover {
  color: #000;
} */
.header-nav-ul-li a::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 2px;
  border-radius: 4px;
  background-color: #ffc200;
  bottom: 16px;
  /* left: 0; */
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.header-nav-ul-li a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  right: 15px;
  color: black;
}

.header-nav-ul-li a:hover {
  color: black;
}
.profile-header-nav-ul-li a::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 2px;
  border-radius: 4px;
  background-color: #ffc200;
  bottom: 3px;
  /* left: 0; */
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.profile-header-nav-ul-li a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  right: 10%;
  color: black;
}

.profile-header-nav-ul-li a:hover {
  color: black;
}
.announcement-bar__text a {
  position: relative !important;
  color: inherit !important;
  text-underline-offset: 0.4rem !important;
  -webkit-text-decoration-color: inherit !important;
          text-decoration-color: inherit !important;
  z-index: 2 !;
}
.header-activateTag-popup {
  font-family: "prompt";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}

.header-input__field__dialogs {
  padding: 0.375em 0.75em !important;
  border: 1px solid black;
  border-radius: 15px;
}

.header-verifyOtp-button {
  font-family: "prompt";
  font-size: 14px;
  font-weight: 500;
  background-color: #ffc107;
  letter-spacing: 1px;
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}

.header-verifyOtp-button:hover {
  background-color: #ebb105;
}

.header-activateTag-popup {
  font-family: "prompt";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}
.logo-container {
  text-align: center;
  margin: 1rem;
}

.logo {
  max-width: 100%;
  margin-bottom: 20px;
}

.logo-image {
  max-width: 70%;
  height: auto;
  max-height: 60px;
}

.navbar-toggle-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  margin-top: -55px;
  position: relative;
  z-index: 3;
}

.header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-nav-hide {
  /* background: whitesmoke; */
  /* position: absolute; */
  width: 100%;
  /* top: 100%;
  right: -100%; */
  /* transition: 250ms; */
}

/* .header-nav-hide {
  background: #f5f5f5;
  position: absolute;
  top: 100%;
  transition: 0.35s;
} */
.header-nav-ul-li {
  margin-right: 10px;
}
.navbar__options span {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: -1rem;
}

.navbar__options {
  color: black;
  font-family: "prompt";
  font-size: 16px !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out;
  padding: 1.7rem 1rem !important;
  border: none;
  background: none;
}
.navbar__options:hover {
  color: black;
  text-decoration: none;
  cursor: default;
  outline: none;
}
.got-genie1 {
  height: 55%;
  padding: 10px;
  margin-right: 1rem;
  font-family: "prompt";
  border-radius: 10px;
  width: 165px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #a78107;
  border-radius: 10px;
  margin-right: 1rem;
}
.header-nav-hide .close-button {
  display: none;
}
/* .got-genie-top {
  color: #00000000;
} */

.got-genie-div-1 {
  display: none;
  margin-left: auto;
  align-items: center;
  overflow: hidden;
  float: left !important;
  outline: none;
}

.got-genie-form {
  margin: 5px;
  padding: 5px;
  margin-right: 4px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  background-color: #24292d;
  border: 1px solid #504d4d;
  border-radius: 10px;
}

.got-genie-form > input {
  width: 70%;
  height: 30px;
  align-self: center;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
.genie-form-buttons {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
}

.genie-form-buttons > button {
  background-color: white;
  padding: 5px 13px;
  margin: 10px 8px;
  margin-top: 12px;
  border-radius: 5px;
}
.genie-form-buttons > button:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.genie-form-buttons > button:hover:disabled {
  cursor: inherit;
}

.genie-form-buttons > button:disabled {
  background-color: #d3d3d3;
}

/* .nav-link {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
} */

@media screen and (max-width: 2000px) {
  .navbar-toggle-btn {
    display: none;
  }
  .header-nav-ul {
    list-style: none;
    /* padding: 0; */
    /* margin: 0; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
  }
  /* .header-nav-hide {
    width: fit-content;
    display: flex;
    top: 0;
    right: 0;
  } */

  /* .header-nav-ul {
    display: flex;
    align-items: center;
    height: 3.3em;
    max-width: 60em;
  } */
}

@media (max-width: 768px) {
  /* .header-nav-ul.show {
    display: flex; /* Show the options when the show class is applied */
  /* } */
  .header-nav-ul {
    list-style: none;
    display: flex;
    flex-direction: column; /* Change direction to column for smaller screens */
    align-items: baseline;
  }

  .header-nav-ul-li a::before {
    display: none; /* Hide the pseudo-element on smaller screens */
  }

  .header-nav-ul-li a:hover::before {
    transform: scaleX(
      0
    ); /* Ensure the transform is reset for smaller screens */
  }
  .navbar-toggle-btn {
    display: block; /* Show the button */
  }
  .header-section {
    height: 65px;
    position: fixed !important;
    background-color: #ffffff;
    /* top: 40px; Ensure the header stays at the top */
    z-index: 1000;
  }
  /* .announcement-bar-1 {
    width: 100%;
  } */

  .logo-container {
    text-align: left;
    margin: 0.8rem 0rem 0.5rem 3rem;
    width: 9rem;
  }
  .announcement-bar__message {
    padding: 0.8rem 1.6rem !important;
  }
  /* .header-nav {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: hsl(0, 13%, 89%);
    padding-top: 50px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

  .header-nav .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 24px;
    color: #333;
    outline: none;
    transition: color 0.3s ease;
  }

  .header-nav .close-button:hover {
    color: #ff0000;
  }

  .header-nav {
    width: 60%;
    height: 100vh;
    position: fixed;
    top: 5.8%;
    right: 0;
    background-color: #ffffff;
    padding-top: -6px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  .header-nav ul {
    list-style: none;
    padding: 0;
    margin: 0; /* Resetting margin to zero */
  }

  .header-nav-ul-li,
  .profile-header-nav-ul-li {
    position: relative;
    margin: 0; /* Ensure no extra margin is added to the list items */
    padding: 0.5rem 0; /* Adding some padding for spacing */
    border-bottom: 1px solid #333; /* Adding a bottom border */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  }

  /* Remove the pseudo-element as we are using border-bottom */
  .header-nav-ul-li:after,
  .profile-header-nav-ul-li:after {
    content: none;
  }

  .header-nav-ul-li:last-child,
  .profile-header-nav-ul-li:last-child {
    border-bottom: none; /* Remove bottom border for the last item */
  }

  /* Optional: Style for the anchor tags inside the list items */
  .header-nav-ul-li a,
  .profile-header-nav-ul-li a {
    display: block; /* Ensure the anchor tags take the full width of the list item */
    padding: 0.5rem 1rem; /* Add some padding for spacing */
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Ensure the color is inherited from the parent */
  }

  .navbar__options {
    padding: 0.4rem 0rem !important;
  }
  .header-nav li a {
    padding: 10px; /* Adjust padding as needed */
    text-decoration: none;
    color: #333;
  }

  .header-nav-hide {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.5s ease-out;
  }
  /* .header-nav-ul-li a::before {
    display: none;
  } */
}
/* @media (max-width: 767px) {
  .logo-container,
  .navbar-toggle-btn {
    display: block;
  }
} */

@media (max-width: 360px) {
  .header-nav {
    top: 9%;
  }
}

.footer {
  width: 100%;
  height: 20px;
  background-color: #272730;
  text-align: center;
  color: white;
  padding-top: 1px;
  padding-left: 15px;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  z-index: 999;
}
.footer .left-content {
  flex: 1 1;
}

.footer .right-content {
  flex: 1 1;
  text-align: center;
}
.footer .left-content {
  flex: 1 1;
}

.footer .right-content {
  flex: 1 1;
  text-align: center;
}
.footer-div {
  width: 100%;
  height: -webkit-max-content !important;
  height: max-content !important;
  background-image: linear-gradient(to right, #ccad5e, #fdcb4d);
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  padding-left: 30px;
  padding-right: 30px;
}

.online-store {
  text-align: center;
  margin-right: 10px;
}

.online-store img {
  height: 60px !important;
  border-radius: 10px;
  box-shadow: 3px 3px 6px grey;
}

.footer-div-3 {
  height: 200px;
  text-align: center;
}

footerdiv3 {
  margin-top: 40px !important;
}

.footer-div-3 div {
  text-align: center !important;
  margin-top: 10px !important;
}

.footer-div4-1 {
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
}

.footer-div4-1 button {
  width: 150px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
}
.footer-div4-2 {
  padding: 10px 5px;
  display: flex;
  flex-direction: "row";
  margin-top: -5px;
}
.footer-div4-2 button {
  width: 210px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
}

.link-btn {
  width: 180px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
  margin-left: -5px;
}

.link-btn1 {
  width: 210px;
  height: 40px;
  background-color: transparent;
  border: 1px solid black;
  margin-right: 5px;
  margin-left: -5px;
}

/* Media Queries */
/* @media screen and (max-height: 600px) {
  .footer {
    position: static;
  }
} */
/* @media screen and (max-height: 600px) {
  .footer {
    position: static;
  }
} */

@media screen and (max-width: 768px) {
  .footer {
    height: 65px;
    margin: auto;
  }
  .footer-div-3 div {
    text-align: left !important;
  }

  .link-btn {
    width: 110px !important;
    height: 40px !important;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 5px;
    margin-left: -5px;
  }

  .link-btn1 {
    width: 150px !important;
    height: 40px !important;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 5px;
    margin-left: -5px;
  }
}

@media screen and (max-width: 400px) {
  .online-store img {
    height: 60px !important;
    width: 90px !important;
  }
  .footer {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
  }
  .footer .left-content,
  .footer .right-content {
    font-size: 10px;
  }
}

.app {
    position: relative;
  }
  .app.sidebar-open .content {
    filter: blur(5px);
  }
  .toggle-button,
  .mobile-toggle-button {
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1050;
    background: none;
    border: none;
    font-size: 24px;
    background-color: #ffffff4d;
    border: 0.75px solid #000 !important;
    border-radius: 4px;
    padding: 4px;
    /* transition: left 0.3s ease; */
  }
  .sidebar-icons {
    width: 1.75rem;
  }
  .sidebar-sub-icons {
    width: 1rem;
  }
  .toggle-button:focus,
  .mobile-toggle-button:focus {
    outline: none !important;
    border: 0.75px solid #000 !important;
  }
  
  .sidebar {
    position: fixed;
    top: 190px;
    left: 0;
    width: 60px;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    /* transition: width 0.3s ease; */
    z-index: 1040;
  }
  .app.sidebar-collapsed .toggle-button {
    left: 175px;
  }
  .sidebar.collapsed {
    width: 220px;
  }
  
  .sidebar-menu {
    padding: 60px 0 0 0;
  }
  
  .menu-item {
    display: flex;
    align-items: center;
    padding: 18px 16px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .menu-item span {
    display: none;
    margin-left: 10px;
  }
  .menu-item svg {
    width: 20px;
    height: 30px;
  }
  .sidebar.collapsed .menu-item span {
    display: inline;
  }
  
  .submenu {
    padding-left: 20px;
  }
  
  .submenu .menu-item {
    padding: 10px 0;
  }
  
  .sidebar-logo {
    padding: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .shop-now-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding: 26rem 3rem 0rem 3rem;
  }
  
  .shop-now-button {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .shop-now-button:hover {
    background-color: black;
    color: white;
  }
  
  @media (max-width: 768px) {
    .toggle-button {
      display: none;
    }
  
    .mobile-toggle-button {
      left: 20px;
      top: 80px;
      padding: 0px;
    }
    .app.sidebar-collapsed .mobile-toggle-button {
      left: 200px;
      top: 80px;
    }
    .sidebar {
      top: 140px !important;
      width: 0;
      height: 100%;
      /* transition: width 0.3s ease; */
    }
  
    .sidebar.collapsed {
      width: 230px;
    }
  
    .app.sidebar-collapsed .sidebar {
      /* top: 0; */
      left: 0;
    }
  }
  
  @media (min-width: 361px) and (max-width: 768px) {
    .sidebar {
      top: 112px !important;
    }
  
    .app.sidebar-collapsed .sidebar {
      top: 116px !important;
    }
  }
  

table {
  padding-top: 20px;
  background-color: white;
  margin-left: 100px !important;
  border-collapse: collapse;
  border-spacing: 0;
  width: 85% !important;
  border: 1px solid #ddd;
}

/* Style table headers and table data */
th, td {
  font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
  letter-spacing: 0.5px;
  text-align: center;
  border: 1px solid black;
  padding: 8px 15px  !important;
}
th{
  font-weight: 700 ;
}

th:first-child, td:first-child {
  text-align: left;
}



.fa-check-circle {
  color: #28a745;
  font-size: 18px;
}

.fa-times-circle {
  color: #dc3545;
  font-size: 18px;
}
@media (max-width: 800px) {
    table{
        margin-left:0px;
    }
}

@media only screen and (max-width:600px){

  .compare{
    display: none !important;
  }

  
  
}
@media (max-width:1260px) {

  .compare{
      margin-left: 0px !important;
  }
}

@media only screen and (max-width:900px)
{

  .compare
  {
    margin-left: -95px !important;
    width: 100% !important;
  }
  
}

/* @media only screen and (max-width:1600px){
  .compare{
    margin-left: 0px !important;
  }
  
} */

/* @media screen and (max-width:1100px) {
  .compare{
    margin-left: -100px !important;
  }
  
} */
.features{
    display: flex;
    padding: 50px 90px;
    background-color: #f1f1f1;
    padding-bottom: 120px;
}

.features > img{
    flex:0.5 1;
    width: 400px;
    height: 300px;
}

/* ----- */
.list-of-features
{
    margin-left: 100px !important;
    display: flex;
    text-align: left;
    background-color :#f7d308  ;
    padding-top: 15px;
    padding-right: 20px !important;
    width: 85% !important;
}
.list-of-features li
{
    text-decoration: none !important;
    margin-top: 5px !important;
}
.list-of-features > ul{
    list-style-type: none;
    flex:0.5 1;
    margin-right: 0px !important;
    
}
.list-of-features ul
{
    margin-left: 15px !important;
    margin-bottom: 30px !important;

}

/* ----- */

.features__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.feature-button{
    border: none;
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    font-size: 18px  !important;
    font-weight: 500;
    padding: 12px 60px;
    border-radius: 3px;
    background-color: #f7d308 ;
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

.feature-button:focus{
    background-color: #f7d308 ;
}

.features__buttons{
    display: flex;
    padding-top: 40px;
    margin-left: 100px !important;
}

.compare-button{
    border: none;
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    font-size: 18px  !important;
    font-weight: 500;
    padding: 12px 60px;
    border-radius: 3px;
    background-color: white;
}

.feature-button:hover{
    cursor: pointer;
    background-color:#e7c606  ;
}

.compare-button:hover{
    cursor: pointer;
    background-color: whitesmoke;
}

.features__list{
    display: flex;
    text-align: left;
    background-color :#f7d308  ;
    padding-top: 15px;
    /* padding-right: 30px; */
    margin-left: 100px;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.features__list > ul{
    list-style-type: none;
    flex:0.5 1;
}

.features__list > ul> li{
    display: flex;
    width: 350px;
    margin-bottom: 10px;
    align-content: center;
    flex-direction: column;
    margin-left: 20px;
    cursor: pointer;
}

.features__list > ul> div{
    margin-left: 75px;
    padding: 0;
}

h3{
    font-weight: 550;
    font-size: 18px;
    padding-top: 3px;
}
.feature__header{
    display: flex;
}

.feature__description{
    margin-left: 45px;
}

.left__list{
    margin-left: 0  !important;
}

.list-bullets{
    color:#212529;
    font-size: 26px;
    margin-right: 20px;
}



@media (max-width: 900px) {

    .features__list li
    {
        margin-top: 0px !important;
    }
    
    .features__list{
        flex-direction: column;
        margin-left: 0;
    }
    .list-of-features
    {
        flex-direction: column !important;
        padding-left: 5px !important;
        margin: auto !important;
        width: 100% !important;
    }
    .features__buttons{
        margin: auto !important;
        
    }
    .list-2
    {
        margin-top: -22px !important;
    }
}

@media only screen and (max-width:600px)
{
    .compare-button
    {
        display: none !important;
    }
    
}

@media only screen and (max-width:500px)
{
    .list-of-features
    {
        width: 100% !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .features
    {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    
}

@media only screen and (max-width:480px) {
    
    .features img{
        /* margin-left: 10px !important; */
        margin: auto !important;
        margin-left: 40px !important;
        text-align: center;
        width: 290px !important;
        height: 250px !important;
    }
    .compare{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
    }
    
   .features__list{
       width: -webkit-fit-content !important;
       width: -moz-fit-content !important;
       width: fit-content !important;
   }
   .left__list{
       width: -webkit-fit-content !important;
       width: -moz-fit-content !important;
       width: fit-content !important;
   }
}

@media only screen and (max-width:768px) and (min-width:480px) {
    .features{
        text-align: center !important;
    }
    
}

@media only screen and (max-width:378px) 
{
    .features img
    {
        margin-left: 10px !important;
    }

}

@media only screen and (max-width:365px) 
{
    .features img
    {
        margin-left: 15px !important;
    }
    
}

@media only screen and (max-width:350px) and (min-width:275px)
{
    .features img
    {
        margin-left: 10px !important;
        width: 200px !important;
        height: 180px !important;
    }
    .fa fa-check-circle
    {
        font-size: 50px !important;
    }
    
}

@media only screen and (max-width:1024px) and (min-width:780px) {
    .features{
        text-align: center !important;
    }
}



.started{
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    background-color: white;
    text-align: center;
    padding: 20px;
}
.started > h1{
    padding: 20px;
    margin-top: 30px;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 35px !important;
    color: #212529;
    padding-bottom: 50px;
}
.started__tiles{
    /* padding: 40px; */
    justify-content: space-evenly;
    display: flex;
}
.started__tile{
    width: 300px;
    padding:40px ;
    padding-top: 0;
    padding-bottom: 80px;
    margin: 20px;
    background-color:#ececec ;
}
.started__tile> img{
    padding-top: 0;
    margin-top: 0;
    width: 240px;
    height: 240px;
}
.started__tile> p{
    font-size: 20px;
    margin-bottom: 45px;
}
.started__tile> a{
    background-color: #ffc107;
    border-radius: 2px;
    margin: 12px;
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
}
.started__tile > a:hover{
    background-color:#d39e00 !important ;
}
.started__how{
    padding-bottom:100px !important;
    margin: auto;
    /* max-height: 550px !important;
    width: 70% !important; */
    
}

.howtgsbutton{
    text-align: center;
}
@media (max-width: 800px) {
    .started__tiles{
        flex-direction: column;
    }
    .started__tile{
        align-self: center;
        
    }

    .started h1{
        
        font-size: 24px !important;
         padding-left: 0px !important;
         padding-right: 0px !important;
    }

    .started__tile{
        width: 100% !important;
        margin: 25px !important;
        height: auto !important;
    }

    .started__tile img{
             text-align: center !important;
             width: 80% !important;
             height: auto !important;
    }

    .started__how{
        margin: 0 !important;
        width: 100% !important;
        height: auto !important;
    }
}
@media screen and (max-width:1200px){
    .started__how{
        width: 70% !important;
    }
}
@media screen and (max-width:800px){
    .started h1{
           font-size: 38px !important;
    }
    
}

@media screen  and (max-width:800px) and (min-width:500px){

    .started__tile{
        width: 400px !important;
        margin: 30px 10px !important;
    }
    
}
@media screen and (max-width:800px) and (min-width:500px) {

    
    .started h1{
        font-size: 38px !important;
 }

    
}

@media screen and (max-width:500px) and (min-width:400px) {
    .started h1{
        font-size: 28px !important;
    }
    
    .started__tile{
        width: 300px !important;
        margin: 30px 10px !important;
    }
}

@media screen and (max-width:400px) {

    .started h1{
        font-size: 22px !important;
    }
    
    .started__tile{
        width: 110% !important;
    }
    .started__how{
        width: 100% !important;
    }
}

@media only screen and (max-width:307px) {
    .started__tile> a{
        width: 200px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}
.home {
  width: 100% !important;
  overflow-x: hidden !important;
}

.home__landing {
  padding: 0;
  background-color: #fdcb4d;
  background-image: url("https://storage.googleapis.com/pettag/qr/07.png");
  min-height: 100vh !important;
  display: flex;
  align-items: center;
}

.home__para > h2 {
  font: 400 24px/32px Roboto, Helvetica Neue, sans-serif;
  font-weight: 700 !important;
  font-size: 24px;
}
.home__para {
  flex: 0.43 1;
  text-align: left;
  padding: 30px;
  padding-right: 0;
  margin-right: 0;
  color: #212529;
}
.home__para > p {
  font: 400 24px/32px Roboto, Helvetica Neue, sans-serif;
  padding-top: 30px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 1px;
}
.home__videoIcon {
  padding-top: 20px;
  margin-top: 20px;
  padding-right: 30px;
}
.home__carousel {
  flex: 0.6 1;
  padding: 20px;
}
button.rec-dot {
  /* visibility: hidden !important; */
  position: relative !important;
  margin-top: -50px;
  z-index: 100;
  background-color: #bfbcbd;
  /* box-shadow: 0 0 1px 3px rgba(235, 16, 16, 0.5); */
}
button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus .rec-dot_active {
  background-color: white;
  box-shadow: none;
  border: none;
  outline: none;
}
.ihThzJ {
  background-color: white !important;
  box-shadow: none !important;
}
.facebook {
  height: 60%;
  width: 100%;
}
.carousel__image {
  height: 60%;
  width: 100%;
}
* {
  padding: 0;
  margin: 0;
}

/* square buttons */
.rec.rec-arrow {
  border-radius: 0;
  visibility: hidden;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 12px;
  height: 50px;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}
.boxplay {
  display: flex;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.playbutton .playbutton:focus {
  animation: zoomeffect 2s infinite;
  border-radius: 50%;
  z-index: 1;
  color: black;
  background-color: transparent;
  box-sizing: border-box;
}

.play-btn:focus .boxplay:focus .playbuttonimage:focus {
  background-color: #fdcb4d !important;
}

.boxplay > span {
  position: absolute;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  color: #212529;
  text-align: left;
  animation: animates 3s linear infinite;
  animation-delay: 0s;
  animation-delay: calc(0.8s * var(--i));
}

.span1 {
  --i: 1;
  background-color: rgb(182, 167, 102);
}

.span2 {
  --i: 2;
  background-color: rgb(182, 167, 102);
}

.span3 {
  --i: 3;
  background-color: rgb(182, 167, 102);
}

@keyframes zoomeffect {
  0% {
    background-position: center;
    transform: scale(1, 1);
  }
  50% {
    background-position: center;
    transform: scale(1.3, 1.3);
  }
  100% {
    background-position: center;
    transform: scale(1, 1);
  }
}
@keyframes animates {
  0% {
    width: 50px;
    height: 50px;
    opacity: 0.8;
    background: transparent !important;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}
@media (max-width: 770px) {
  .home__landing {
    flex-direction: column-reverse;
  }

  .new_para_home {
    margin-top: 260px !important;
  }
  .home__carousel {
    margin-top: 0px !important;
  }
}

@media (min-width: 770px) {
  .home__landing {
    margin-top: 14px !important;
  }

  .home__carousel {
    margin-top: -100px !important;
  }
}

@media screen and (max-width: 850px) {
  .rec-carousel {
    position: relative !important;
  }
  /* check */
  .button.rec-dot {
    position: absolute;
    left: 50% !important;
    bottom: 50px !important;

    margin-top: -50px !important;
  }
}

@media screen and (max-width: 650px) {
  .rec-pagination {
    position: relative;
    top: -60 px !important;
  }

  .rec-dot {
    height: 8px !important;
    width: 8px !important;
  }
}

@media (max-width: 768px) and (min-width: 280px) {
  .home__carousel {
    margin-top: 35px !important;
    position: relative !important;
    width: 100% !important;
    max-width: 700px !important;
    height: 100% !important;
  }

  .home__para {
    margin-top: 0px !important;
    margin-left: 25px !important;
    margin-right: 25px !important;
    margin-bottom: 25px !important;
    font-size: medium;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .home__para h2 {
    font-size: 28px !important;
  }

  .home__para p {
    padding-top: 8px !important;
    font-size: 15px !important;
  }
}

@media (max-width: 1600px) and (min-width: 1067px) {
  .home__carousel {
    margin-top: 60px !important;
  }
}

@media (max-width: 1024px) {
  .home__landing {
    margin-top: 73px !important;
  }
}
@media (max-width: 500px) {
  .home__para {
    margin-top: 0px !important;
  }
}
@media (max-width: 412px) {
  .carousel__image {
    height: 60%;
    width: 100%;
    margin-top: 2.5rem;
  }
}

