
table {
  padding-top: 20px;
  background-color: white;
  margin-left: 100px !important;
  border-collapse: collapse;
  border-spacing: 0;
  width: 85% !important;
  border: 1px solid #ddd;
}

/* Style table headers and table data */
th, td {
  font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
  letter-spacing: 0.5px;
  text-align: center;
  border: 1px solid black;
  padding: 8px 15px  !important;
}
th{
  font-weight: 700 ;
}

th:first-child, td:first-child {
  text-align: left;
}



.fa-check-circle {
  color: #28a745;
  font-size: 18px;
}

.fa-times-circle {
  color: #dc3545;
  font-size: 18px;
}
@media (max-width: 800px) {
    table{
        margin-left:0px;
    }
}

@media only screen and (max-width:600px){

  .compare{
    display: none !important;
  }

  
  
}
@media (max-width:1260px) {

  .compare{
      margin-left: 0px !important;
  }
}

@media only screen and (max-width:900px)
{

  .compare
  {
    margin-left: -95px !important;
    width: 100% !important;
  }
  
}

/* @media only screen and (max-width:1600px){
  .compare{
    margin-left: 0px !important;
  }
  
} */

/* @media screen and (max-width:1100px) {
  .compare{
    margin-left: -100px !important;
  }
  
} */